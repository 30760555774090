var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "default-calculator-layout",
    {
      attrs: {
        "filter-width": _vm.filterWidth,
        "output-width": _vm.outputWidth,
        calcFilter: _vm.calcFilter,
        "calculator-title": _vm.calculatorTitle,
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "filter" }, slot: "filter" },
        [
          _c("number-input", {
            attrs: { label: _vm.labelCurrentMortgageBalance, type: "tel" },
            model: {
              value: _vm.CurrentMortgageBalance,
              callback: function ($$v) {
                _vm.CurrentMortgageBalance = $$v
              },
              expression: "CurrentMortgageBalance",
            },
          }),
          _vm._v(" "),
          _c("number-input", {
            attrs: { label: _vm.labelCurrentMonthlyPayment, type: "tel" },
            model: {
              value: _vm.CurrentMonthlyPayment,
              callback: function ($$v) {
                _vm.CurrentMonthlyPayment = $$v
              },
              expression: "CurrentMonthlyPayment",
            },
          }),
          _vm._v(" "),
          _c("number-input", {
            attrs: {
              label: _vm.labelNewInterestRate,
              "tooltip-link": _vm.InterestRateTooltip,
              symbol: "%",
              "symbol-aligned": "right",
              precision: "3",
              maxValue: "100.000",
              type: "number",
            },
            model: {
              value: _vm.NewInterestRate,
              callback: function ($$v) {
                _vm.NewInterestRate = $$v
              },
              expression: "NewInterestRate",
            },
          }),
          _vm._v(" "),
          _c("drop-down", {
            attrs: {
              label: _vm.labelLoanTermInYears,
              options: _vm.LoanTermInYearsList,
              "tooltip-link": _vm.LoanTermTooltip,
            },
            model: {
              value: _vm.LoanTermInYears,
              callback: function ($$v) {
                _vm.LoanTermInYears = $$v
              },
              expression: "LoanTermInYears",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "output" }, slot: "output" },
        [
          _c("div", { staticClass: "loan-comparison" }, [
            _c("h4", [_c("b", [_vm._v("Loan comparison:")])]),
            _vm._v(" "),
            _c("table", { staticClass: "table table-border-none" }, [
              _c("tbody", [
                _c("tr", [
                  _c("td", { attrs: { width: "70%" } }, [
                    _vm._v("Current Monthly Payment:"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    {
                      staticClass: "payment text-right",
                      attrs: { width: "30%" },
                    },
                    [_vm._v(_vm._s(_vm.displayCurrentMonthlyPayment))]
                  ),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("New Monthly Payment:")]),
                  _vm._v(" "),
                  _c(
                    "td",
                    {
                      staticClass: "payment text-right",
                      class: {
                        "red-text": !_vm.differenceIncrOrDecr,
                        "green-text": _vm.differenceIncrOrDecr,
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.displayMonthlyPayment) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "analysis-results" }, [
            _c("ul", [
              _vm.differenceIncrOrDecr !== 0
                ? _c(
                    "li",
                    {
                      class: {
                        "green-arrow": _vm.differenceIncrOrDecr,
                        "red-arrow": !_vm.differenceIncrOrDecr,
                      },
                    },
                    [
                      _c("i", { staticClass: "lt4-ArrowDownCircle" }),
                      _vm._v(" "),
                      _c("i", { staticClass: "lt4-ArrowUpCircle" }),
                      _vm._v("\n                    Refinancing "),
                      !_vm.differenceIncrOrDecr
                        ? _c("big", [_vm._v("Increases")])
                        : _vm._e(),
                      _vm.differenceIncrOrDecr
                        ? _c("big", [_vm._v("Decreases")])
                        : _vm._e(),
                      _vm._v(" your monthly payment by "),
                      _c("span", { staticClass: "result-amount" }, [
                        _vm._v(_vm._s(_vm.differenceMonthlyPayment)),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.differenceIncrOrDecr === 0
                ? _c(
                    "li",
                    { staticClass: "no-arrow" },
                    [
                      _vm._v("\n                    Refinancing "),
                      _c("big", [_vm._v("does not change")]),
                      _vm._v(
                        " your monthly payment. Try changing your loan term.\n                "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "compare-offers" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-blue",
                attrs: { type: "submit" },
                on: {
                  click: function ($event) {
                    return _vm.redirect()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.DestinationButtonText))]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "disclosures" },
            [
              _vm.DisclosureText
                ? _c("advertising-disclosures", {
                    attrs: {
                      "disclosure-pre-text": _vm.disclosurePreText,
                      "disclosure-text": _vm.DisclosureText,
                      "disclosure-tokens": _vm.DisclosureTokens,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.ltLogo === 1
            ? _c("lt-logo", [
                _c("small", [_vm._v("NMLS#1136 Terms & Conditions Apply")]),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import load from 'little-loader';
import Vuelidate from 'vuelidate';
import VueMask from 'v-mask';
import VueLocalStorage from 'vue-ls';
import DynamicPropsFromAttr from '../mixin/dynamic-props-from-attr';

const initApp = (version, app, context) => {
    const runtime = version === 'full' ? '' : '.runtime';
    const min = process.env.LT_ENV === 'prod' ? '.min' : '';
    const script = `https://unpkg.com/vue@2.7.16/dist/vue${runtime}${min}.js`;

    load(script, app, context);
};

const loadApp = (selector, App, version) => {
    /**
     * loadingInitApp
     */
    function loadingInitApp() {
        initApp(version, () => {
            Vue.use(Vuelidate);
            Vue.use(VueMask);
            const options = {
                namespace: 'lt-yantr_',
            };
            Vue.use(VueLocalStorage, options);

            return new Vue({
                mixins: [DynamicPropsFromAttr],
                el: selector,
                components: {
                    App,
                },
            });
        });
    }

    const element = document.querySelector(selector);

    if (element?.getAttribute('init-lazy-loading') === 'false' || document.readyState === 'complete') {
        loadingInitApp();
    } else {
        document.addEventListener('readystatechange', () => {
            if (document.readyState === 'interactive') {
                if ('IntersectionObserver' in window) {
                    const elm = document.querySelector(selector);
                    const observer = new IntersectionObserver((entry, _) => {
                        for (let i = 0, len = entry.length; i < len; i++) {
                            if (entry[i].isIntersecting) {
                                loadingInitApp();
                            }
                        }
                    });
                    if (elm !== null) {
                        observer.observe(elm);
                    }
                } else {
                    loadingInitApp();
                }
            }
        });
    }
};

export default loadApp;

// TODO (skanigiri): Move this to helpers

export default {
    set(key, value, expiration) {
        try {
            Vue.ls.set(key, value, expiration);
        } catch (e) {
            // Ignore the error
        }
    },
    get(key) {
        try {
            return Vue.ls.get(key);
        } catch (e) {
            return null;
        }
    },
};

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showTooltip
    ? _c(
        "label",
        { attrs: { for: _vm.forTag } },
        [
          _vm._v("\n    " + _vm._s(_vm.label) + " "),
          _c("tooltip-view", {
            attrs: {
              label: _vm.label,
              "show-question-mark": _vm.showQuestionMark,
              "popper-content": _vm.popperContent,
              referenceElement: "open me",
            },
          }),
        ],
        1
      )
    : _vm.label
    ? _vm._m(0)
    : _c(
        "span",
        [
          _vm.tooltipLink
            ? _c("tooltip", {
                attrs: {
                  "tooltip-link": _vm.tooltipLink,
                  "tooltip-text": _vm.tooltipText,
                  "tooltip-class": _vm.tooltipClass,
                  "unique-id": _vm.uniqueId,
                  "show-tooltip-loader": _vm.showTooltipLoader,
                },
              })
            : _vm._e(),
        ],
        1
      )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { attrs: { for: _vm.forTag } },
      [
        _vm._v("\n    " + _vm._s(_vm.label) + " "),
        _vm.tooltipLink
          ? _c("tooltip", {
              attrs: {
                "tooltip-link": _vm.tooltipLink,
                "unique-id": _vm.uniqueId,
                "show-tooltip-loader": _vm.showTooltipLoader,
              },
            })
          : _vm._e(),
      ],
      1
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
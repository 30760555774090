var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _c("lt-label", {
        attrs: {
          label: _vm.label,
          "popper-content": _vm.popperContent,
          showTooltip: _vm.showTooltip,
          "show-question-mark": _vm.showQuestionMark,
          "tooltip-link": _vm.tooltipLink,
          "show-tooltip-loader": _vm.showTooltipLoader,
          "for-tag": _vm.id,
        },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "small-text" }, [
        _vm._v(_vm._s(_vm.smalltext)),
      ]),
      _vm._v(" "),
      _vm.setSelected
        ? _c(
            "select",
            {
              ref: "input",
              class: _vm.classValue,
              attrs: { id: _vm.id, "v-model": _vm.Value },
              domProps: { value: _vm.value },
              on: {
                change: function ($event) {
                  return _vm.updateValue($event.target.value)
                },
              },
            },
            [
              _c("option", { attrs: { disabled: "", value: "" } }, [
                _vm._v(_vm._s(_vm.DefaultOption)),
              ]),
              _vm._v(" "),
              _vm._l(_vm.options, function (option, index) {
                return _c(
                  "option",
                  {
                    key: index,
                    domProps: {
                      value: option.value,
                      selected: option.value == _vm.Value,
                    },
                  },
                  [
                    _vm._v(
                      "\n            " + _vm._s(option.text) + "\n        "
                    ),
                  ]
                )
              }),
            ],
            2
          )
        : _c(
            "select",
            {
              ref: "input",
              class: _vm.classValue,
              attrs: { id: _vm.id },
              domProps: { value: _vm.value },
              on: {
                change: function ($event) {
                  return _vm.updateValue($event.target.value)
                },
              },
            },
            _vm._l(_vm.options, function (option, index) {
              return _c(
                "option",
                {
                  key: `${index}-${option.value}`,
                  domProps: { value: option.value },
                },
                [_vm._v("\n            " + _vm._s(option.text) + "\n        ")]
              )
            }),
            0
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export default {
    props: {
        filterWidth: {
            default: 'col-xs-12 col-sm-5 col-md-4',
        },
        outputWidth: {
            default: 'col-xs-12 col-sm-7 col-md-8',
        },
        calcFilter: {
            default: 'calc-filter',
        },
        calculatorTitle: {
        },
        calculatorSubTitle: {
        },
        filterTitle: {
            default: false,
        },
    },
};

const LoanTermInYearsList = {
    mortgage: [
        { text: '1 Year Term', value: 1 },
        { text: '2 Year Term', value: 2 },
        { text: '3 Year Term', value: 3 },
        { text: '4 Year Term', value: 4 },
        { text: '5 Year Term', value: 5 },
        { text: '10 Year Term', value: 10 },
        { text: '15 Year Term', value: 15 },
        { text: '20 Year Term', value: 20 },
        { text: '25 Year Term', value: 25 },
        { text: '30 Year Term', value: 30 },
        { text: '40 Year Term', value: 40 },
    ],
    student: [
        { text: '5 Years', value: 5 },
        { text: '10 Years', value: 10 },
    ],
    refinance: [
        { text: '15 Years', value: 15 },
        { text: '30 Years', value: 30 },
    ],
    debtconsolidation: [
        { text: '1 Year Term', value: 1 },
        { text: '2 Year Term', value: 2 },
        { text: '3 Year Term', value: 3 },
        { text: '4 Year Term', value: 4 },
        { text: '5 Year Term', value: 5 },
        { text: '6 Year Term', value: 6 },
        { text: '7 Year Term', value: 7 },
        { text: '8 Year Term', value: 8 },
        { text: '9 Year Term', value: 9 },
        { text: '10 Year Term', value: 10 },
    ],
    auto: [
        { text: '2 Years', value: 2 },
        { text: '3 Years', value: 3 },
        { text: '4 Years', value: 4 },
        { text: '5 Years', value: 5 },
        { text: '6 Years', value: 6 },
        { text: '7 Years', value: 7 },
    ],
    autoloanterm: [
        { text: '3 Years', value: 3 },
        { text: '4 Years', value: 4 },
        { text: '5 Years', value: 5 },
        { text: '6 Years', value: 6 },
        { text: '7 Years', value: 7 },
    ],
    autorefinance: [
        { text: '7 Years', value: 7 },
        { text: '6 Years', value: 6 },
        { text: '5 Years', value: 5 },
        { text: '4 Years', value: 4 },
        { text: '3 Years', value: 3 },
        { text: '2 Years', value: 2 },
    ],
    personal: [
        { text: '1 Year Term', value: 1 },
        { text: '2 Year Term', value: 2 },
        { text: '3 Year Term', value: 3 },
        { text: '4 Year Term', value: 4 },
        { text: '5 Year Term', value: 5 },
        { text: '10 Year Term', value: 10 },
    ],
    fhaloantype: [
        { text: '30 Yr Fixed', value: 30 },
        { text: '15 Yr Fixed', value: 15 },
    ],
    va: [
        { text: '30 Yr Fixed', value: 30 },
        { text: '15 Yr Fixed', value: 15 },
    ],
    oemfinancecalc: [
        { text: '1 Year Term', value: 1 },
        { text: '2 Year Term', value: 2 },
        { text: '3 Year Term', value: 3 },
        { text: '4 Year Term', value: 4 },
        { text: '5 Year Term', value: 5 },
        { text: '6 Year Term', value: 6 },
        { text: '7 Year Term', value: 7 },
    ],
};

const LoanTermInMonthsList = [
    { text: '12 Months', value: 12 },
    { text: '24 Months', value: 24 },
    { text: '36 Months', value: 36 },
    { text: '48 Months', value: 48 },
    { text: '60 Months', value: 60 },
];

const BusinessRefinanceLoanTerms = [
    { text: '1 Month', value: 1 },
    { text: '2 Months', value: 2 },
    { text: '3 Months', value: 3 },
    { text: '4 Months', value: 4 },
    { text: '5 Months', value: 5 },
    { text: '6 Months', value: 6 },
    { text: '7 Months', value: 7 },
    { text: '8 Months', value: 8 },
    { text: '9 Months', value: 9 },
    { text: '10 Months', value: 10 },
    { text: '11 Months', value: 11 },
    { text: '12 Months', value: 12 },
    { text: '13 Months', value: 13 },
    { text: '14 Months', value: 14 },
    { text: '15 Months', value: 15 },
    { text: '16 Months', value: 16 },
    { text: '17 Months', value: 17 },
    { text: '18 Months', value: 18 },
    { text: '19 Months', value: 19 },
    { text: '20 Months', value: 20 },
    { text: '21 Months', value: 21 },
    { text: '22 Months', value: 22 },
    { text: '23 Months', value: 23 },
    { text: '24 Months', value: 24 },
    { text: '25 Months', value: 25 },
    { text: '26 Months', value: 26 },
    { text: '27 Months', value: 27 },
    { text: '28 Months', value: 28 },
    { text: '29 Months', value: 29 },
    { text: '30 Months', value: 30 },
    { text: '31 Months', value: 31 },
    { text: '32 Months', value: 32 },
    { text: '33 Months', value: 33 },
    { text: '34 Months', value: 34 },
    { text: '35 Months', value: 35 },
    { text: '36 Months', value: 36 },
];

const CompoundIntervalList = [
    { text: 'Daily', value: 365 },
    { text: 'Monthly', value: 12 },
    { text: 'Quarterly', value: 4 },
    { text: 'Annually', value: 1 },
];

const LoanTermAutoLoansCTAList = [
    { text: '84 Months', value: 84 },
    { text: '72 Months', value: 72 },
    { text: '60 Months', value: 60 },
    { text: '48 Months', value: 48 },
    { text: '36 Months', value: 36 },
    { text: '24 Months', value: 24 },
    { text: '12 Months', value: 12 },
];

export default {
    LoanTermInYearsList, LoanTermInMonthsList, BusinessRefinanceLoanTerms, CompoundIntervalList, LoanTermAutoLoansCTAList,
};

/**
 * This file reads end point from WWW
 */
export default {
    getWWWURL() {
        const config = {
            local: 'https://www.dev.lendingtree.com',
            prod: 'https://www.lendingtree.com',
            dev: 'https://www.dev.lendingtree.com',
            stage: 'https://www.staging.lendingtree.com',
        }[process.env.LT_ENV || 'dev'];
        return config;
    },

    isThirdPartySite() {
        return window.location.hostname.indexOf('.lendingtree') < 0 && window.location.hostname.indexOf('localhost') < 0;
    },
};

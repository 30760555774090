var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _vm.disclosureTokens
        ? _c(
            "button",
            {
              staticClass: "disclosure-link a11y-modal-trigger",
              attrs: {
                id: _vm.disclosurBtnId,
                "data-target": _vm.disclosureIdTrigger,
                target: _vm.target,
              },
            },
            [_vm._v(_vm._s(_vm.disclosureText))]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "a11y-modal",
        {
          attrs: { id: _vm.disclosureIdTrigger },
          scopedSlots: _vm._u([
            {
              key: "h4",
              fn: function () {
                return [_vm._v(" " + _vm._s(_vm.DisclosureHeadingText) + " ")]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("div", {
            staticClass: "disclosures-section",
            attrs: { slot: "body" },
            domProps: { innerHTML: _vm._s(_vm.ModalBody) },
            slot: "body",
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "logo-wrapper" },
    [_vm._m(0), _vm._v(" "), _vm._t("default")],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "lt-logo",
        attrs: { href: "https://www.lendingtree.com/", target: "_blank" },
      },
      [
        _c("img", {
          attrs: {
            src: require("../../assets/images/LT_logo.svg"),
            alt: "LendingTree",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
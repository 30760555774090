const defaultTooltip = {
    HomePrice: 'home-price',
    HomeVal: 'home-value',
    DownPayment: 'down-payment',
    VaDownPayment: 'down-payment',
    CreditScore: 'credit-score',
    FhaDownPayment: 'fha-down-payment',
    LoanAmount: 'loan-amount',
    InterestRate: 'interest-rate',
    LoanTerm: 'loan-terms',
    AnnualIncome: 'annual-income',
    CurrentAppraisedValue: 'appraisal',
    AmountOwe: 'current-balance',
    LoanType: 'loan-terms',
    MonthlyExpenses: 'monthly-debt',
    PrivateMortgageInsurance: 'private-mortgage-insurance',
    DebtToIncomeRatioDti: 'debt-to-income-ratio-dti',
    TotalInterest: 'total-interest',
    TotalLoanAmount: 'total-loan-amount',
    TotalOfAllPayment: 'total-of-all-payments',
    TaxesFees: 'taxes-fees',
    SalesTax: 'sales-tax-percentage',
    NetTradeValue: 'net-trade-in-value',
    Title: 'title',
    CarAnnualPercentageRate: 'car-annual-percentage-rate',
    AmountOwedOnTrade: 'amount-owed-on-trade',
    CarDownPayment: 'car-down-payment',
    TradeInValue: 'trade-in-value',
    APR: 'apr',
    AmountOwed: 'amount-owed',
    CreditCardDebt: 'dma-credit-card-debt',
    PersonalLoans: 'dma-personal-loans',
    MedicalExpenses: 'dma-medical-expenses',
    OtherDebt: 'dma-other-debt',
    DebtSettlementOption: 'dma-debt-settlement-option',
    CreditCardOption: 'dma-credit-card-option',
    PersonalLoanOption: 'dma-personal-loan-option',
    HomeEquityOption: 'dma-home-equity-option',
    DebtSettlementSaving: 'dma-debt-settlement-savings',
    BalanceTransferSaving: 'dma-balance-transfer-card-savings',
    HomeEquitySaving: 'dma-home-equity-savings',
    PersonalLoanSaving: 'dma-personal-loan-savings',
    DebtCovered: 'dma-debt-covered',
    Interest: 'dma-interest',
    MonthlyPayment: 'dma-monthly-payment',
    DebtNotCovered: 'dma-debt-not-covered',
    LastMonthsDeposits: 'last-months-deposits',
    HomeBased: 'home-based',
    ExistingLoanInformation: 'existing-loan-information',
    TotalLoanRepayment: 'total-loan-repayment',
    CurrentCreditScore: '4cs-of-creditworthiness',
    CompoundInterval: 'compound-interval',
    RateOfReturn: 'rate-of-return',
    AnnualInterestRate: 'annual-interest-rate',
    RetirementAge: 'retirement-age',
    InitialSavings: 'initial-savings',
    Mortgage: 'mortgage',
};

export default defaultTooltip;

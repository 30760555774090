/**
 * This file helps redirection using splitter or final destination url
 */
import URLs from '../constants/urls';

export default {
    methods: {
        redirectToDestinationUrl(name) {
            if (typeof this.DestinationURL !== 'undefined' && this.DestinationURL !== '') {
                if (name) {
                    window.open(this.DestinationURL, name);
                } else {
                    window.open(this.DestinationURL, '_self');
                }
            }
        },
        setDestinationBaseURL() {
            let url = '';
            if (typeof this.DestinationLinkOrId !== 'undefined' && this.DestinationLinkOrId !== '') {
                if (this.DestinationLinkOrId.indexOf('http') === 0) {
                    url = this.DestinationLinkOrId;
                } else {
                    url = `${URLs.LT_WWW_SPITTER_URL + this.DestinationLinkOrId}`;
                }
            }
            return url;
        },
        getRcodeIcode() {
            let icode;
            let rcode;

            if (sessionStorage.icode !== undefined && !isNaN(sessionStorage.icode)) {
                icode = `${sessionStorage.icode}`;
            }
            if (sessionStorage.rcode !== undefined && !isNaN(sessionStorage.rcode)) {
                rcode = `${sessionStorage.rcode}`;
            }
            return { icode, rcode };
        },
    },
};

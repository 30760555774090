var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c(
        "button",
        {
          class: _vm.tooltipClass,
          attrs: {
            id: _vm.triggerBtnId,
            "data-target": _vm.TooltipId,
            "aria-label": _vm.ariaLabel,
          },
          on: {
            click: function ($event) {
              return _vm.handleClick()
            },
          },
        },
        [_vm._v(_vm._s(_vm.tooltipText))]
      ),
      _vm._v(" "),
      _c(
        "a11y-modal",
        {
          attrs: { id: _vm.TooltipId, showLoader: _vm.ShowLoader },
          scopedSlots: _vm._u([
            {
              key: "h4",
              fn: function () {
                return [_vm._v(" " + _vm._s(_vm.GlossaryHeading) + " ")]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("div", {
            attrs: { slot: "body" },
            domProps: { innerHTML: _vm._s(_vm.ModalBody) },
            slot: "body",
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
// VARIABLES
let initialTrigger;
let activeModal;
let firstFocus;
let lastFocus;
let self;

export default {
    // Setup
    setupModal() {
        const firstFocusClass = 'a11y-modal-first-focus';
        const lastFocusClass = 'a11y-modal-last-focus';
        const modal = document.querySelectorAll('.a11y-modal');
        const focusElements = 'button:not([hidden]):not([disabled]), [href]:not([hidden]), input:not([hidden]):not([type="hidden"]):not([disabled]), select:not([hidden]):not([disabled]), textarea:not([hidden]):not([disabled]), [tabindex="0"]:not([hidden]):not([disabled]), summary:not([hidden]), [contenteditable]:not([hidden]), audio[controls]:not([hidden]), video[controls]:not([hidden])';

        for (let i = 0; i < modal.length; i++) {
            const thisModal = modal[i];
            const thisModalId = thisModal.getAttribute('id');
            const thisHeading = thisModal.querySelector('.a11y-modal-title:first-of-type');
            const focusable = thisModal.querySelectorAll(focusElements);

            thisHeading.id = `${thisModalId}_heading`;
            const thisHeadingId = thisHeading.getAttribute('id');

            thisModal.setAttribute('role', 'dialog');
            thisModal.hidden = true;
            thisModal.setAttribute('aria-labelledby', thisHeadingId);

            this.setupClose(thisModal);

            focusable[0].classList.add(firstFocusClass);
            focusable[focusable.length - 1].classList.add(lastFocusClass);
        }
    },
    setupTrigger() {
        const modalTrigger = document.querySelectorAll('.a11y-modal-trigger');
        let thisTrigger;
        self = this;
        for (let i = 0; i < modalTrigger.length; i++) {
            thisTrigger = modalTrigger[i];
            thisTrigger.addEventListener('click', this.openModal);
            thisTrigger.addEventListener('keydown', this.keyClick, false);
        }
    },
    setupClose(thisModal) {
        const modalClose = thisModal.querySelectorAll('.a11y-modal .a11y-modal-close');

        for (let i = 0; i < modalClose.length; i++) {
            modalClose[i].setAttribute('aria-label', 'Close');
            modalClose[i].addEventListener('click', this.closeModal);
        }

        document.addEventListener('keydown', this.keyClick, false);
    },
    // Move modal as first child of body
    moveModal() {
        const { body } = document;
        const modal = document.querySelectorAll('.a11y-modal');
        const firstEl = body.firstElementChild || null;

        for (let i = 0; i < modal.length; i++) {
            body.insertBefore(modal[i], firstEl);
        }

        // Insert modal overlay
        body.insertAdjacentHTML('beforeend', '<div class="a11y-modal-overlay"></div>');
    },

    /**
     * Open modal
     * Trigger opens modal with matching id
     * Add a11y-modal-open class
     * Set aria-hidden=false to modal
     * Body content gets aria-hidden=true
     * Overlay underneath modal
     * Set focus to close button
     */

    openModal() {
        const { body } = document;
        const targetModal = this.dataset.target;
        activeModal = document.getElementById(targetModal);
        const focusTarget = activeModal.querySelector('.a11y-modal-close');
        initialTrigger = this.id;
        const bodyChildren = document.querySelectorAll('body > *:not(.a11y-modal)');

        if (!body.classList.contains('a11y-modal-open')) {
            body.classList.add('a11y-modal-open');

            for (let i = 0; i < bodyChildren.length; i++) {
                bodyChildren[i].setAttribute('aria-hidden', 'true');
            }
        }

        activeModal.removeAttribute('hidden');

        focusTarget.focus();
        document.addEventListener('click', self.outsideClose, false);
        document.addEventListener('touchend', self.outsideClose, false);

        return [initialTrigger, activeModal];
    }, // openModal

    /**
     * Keyboard controls
     * Tab focus remains within modal when open
     * ESC closes the modal
     */

    keyClick(e) {
        const { body } = document;
        const firstFocusClass = 'a11y-modal-first-focus';
        const lastFocusClass = 'a11y-modal-last-focus';
        const keyCode = e.keyCode || e.which;
        const keys = {
            esc: 27,
            enter: 13,
            space: 32,
            tab: 9,
        };

        if (e.target.classList.contains('a11y-modal-trigger')) {
            switch (keyCode) {
                case keys.enter:
                case keys.space:
                    e.preventDefault();
                    e.target.click();
                    break;
                default:
                    break;
            }
        }

        if (body.classList.contains('a11y-modal-open')) {
            switch (keyCode) {
                case keys.esc:
                    self.closeModal();
                    break;
                default:
                    break;
            }

            if (body.classList.contains('a11y-modal-open')) {
                // Get first and last focusable elements
                firstFocus = activeModal.getElementsByClassName(firstFocusClass)[0];
                lastFocus = activeModal.getElementsByClassName(lastFocusClass)[0];
            }

            if (document.activeElement.classList.contains(lastFocusClass)) {
                if (keyCode === keys.tab && !e.shiftKey) {
                    e.preventDefault();
                    firstFocus.focus();
                }
            }

            if (document.activeElement.classList.contains(firstFocusClass)) {
                if (keyCode === keys.tab && e.shiftKey) {
                    e.preventDefault();
                    lastFocus.focus();
                }
            }
        }
    }, // keyClick

    /**
     * Click outside modal to close
     */

    outsideClose(e) {
        const { body } = document;
        if (body.classList.contains('a11y-modal-open') && !e.target.classList.contains('a11y-modal-trigger')) {
            const isClickInside = activeModal.contains(e.target);
            if (!isClickInside) {
                self.closeModal();
            }
        }
    },

    /**
     * Close modal
     * Remove a11y-modal-open class
     * Set modal to aria-hidden=true
     * Set body content to aria-hidden=false
     * Remove overlay
     * Set focus back to trigger with matching id
     */

    closeModal() {
        const { body } = document;
        const modal = document.querySelectorAll('.a11y-modal');
        const trigger = document.getElementById(initialTrigger);
        const bodyChildren = document.querySelectorAll('body > *:not(.a11y-modal)');

        for (let i = 0; i < bodyChildren.length; i++) {
            bodyChildren[i].setAttribute('aria-hidden', 'false');
        }

        body.classList.remove('a11y-modal-open');

        for (let j = 0; j < modal.length; j++) {
            if (!modal[j].hasAttribute('hidden')) {
                modal[j].hidden = true;
            }
        }

        if (trigger !== null) {
            trigger.focus();
        }

        // Reset values
        initialTrigger = undefined;
        activeModal = undefined;

        return [initialTrigger, activeModal];
    }, // closeModal

    // Initialize Function
    initModal() {
        this.moveModal();
        this.setupModal();
        this.setupTrigger();
        const listEl = document.querySelectorAll('.a11y-modal-overlay');
        listEl.forEach((index, i) => {
            if (i > 0) {
                listEl[i].remove();
            }
        });
    },
};

// components
import Accounting from 'accounting';
import DefaultCalculatorLayout from '../layout/default-calculator.vue';
import NumberInput from '../components/number-input.vue';
import DropDown from '../components/drop-down.vue';
import AdvertisingDisclosures from '../components/advertising-disclosures.vue';

// mixin
import LowestApr from './lowest-apr';
import Redirection from './redirection';
import InteractionTracking from './interaction-tracking';
import CommonLayoutProps from './common-layout-props';

Accounting.settings.currency.format = {
    pos: '%s%v', // for positive values, eg. "$1.00" (required)
    neg: '-%s%v', // for negative values, eg. "-$1.00" [optional]
    zero: '%s%v', // for zero values, eg. "$0.00" [optional]
};

export default {
    props: {
        hideTooltip: {
            default: 0,
        },
    },
    computed: {
        DestinationURL() {
            return this.setDestinationBaseURL(); // in future we can append params to sent
        },
    },
    methods: {
        updateInitialInterestRate(val) {
            this.NewInterestRate = val;
        },
        formatFigure(figure) {
            return Accounting.formatMoney(figure, { symbol: '', precision: 0 });
        },
        unformatFigure(figure) {
            return Accounting.unformat(figure);
        },
        navigate(lenderId) {
            this.DestinationLinkOrId = `${this.DestinationLinkOrId}&userselectedlender=${lenderId}`;
            this.redirect();
        },
        formatToMoney(figure) {
            return Accounting.formatMoney(Math.abs(figure), { precision: 0 });
        },
        currencyFormatter(value) {
            const localValue = this.unformatFigure(value);
            const formatedValue = this.formatToMoney(localValue);
            return localValue < 0 ? `(${formatedValue})` : formatedValue;
        },
    },
    components: {
        NumberInput,
        DropDown,
        AdvertisingDisclosures,
        DefaultCalculatorLayout,
    },
    mixins: [LowestApr, Redirection, InteractionTracking, CommonLayoutProps],
};

/**
 * This file gets lowest APR from token
 * @todo: Read from token once its available in wordpress.
 */
import TokenService from '../services/token';

export default {
    methods: {
        fetchData() {
            if (typeof this.interestRate == 'undefined') {
                TokenService.GetTokenValue(this.InterestRateToken, this.updateInitialInterestRate);
            }
        },
    },
    created() {
        this.fetchData();
    },
};

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.IsPopperHidden,
              expression: "!IsPopperHidden",
            },
          ],
        },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _vm._t("reference"),
      _vm._v("\n    " + _vm._s(_vm.checkPopperVisiblity) + "\n"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "a11y-modal", attrs: { id: _vm.id } }, [
    _c("div", { staticClass: "a11y-modal-dialog" }, [
      _c("div", { staticClass: "a11y-modal-content" }, [
        _c("div", { staticClass: "a11y-modal-header" }, [
          _c("button", {
            staticClass: "a11y-modal-close a11y-modal-close-x",
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          }),
          _vm._v(" "),
          _c("h4", { staticClass: "a11y-modal-title" }, [_vm._t("h4")], 2),
        ]),
        _vm._v(" "),
        _vm.showLoader
          ? _c("div", { staticClass: "ltFormBusySpinner" }, [_vm._m(0)])
          : _c(
              "div",
              { staticClass: "a11y-modal-body" },
              [
                _vm._t("body", function () {
                  return [
                    _vm._v(
                      "\n                    default body\n                "
                    ),
                  ]
                }),
              ],
              2
            ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "spin" }, [
      _c("div", { staticClass: "inner" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
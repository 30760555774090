/***
 * This file is specifically for making simple ajax calls(uses localStorage for caching)
 *
 * Parameters for makeHttpRequest method:
 *   {
 *       method: String (optional),
 *       url: String,
 *       params: String | Object (optional),
 *       headers: Object (optional),
 *       storage_key: String (optional)
 *   }
 *
 * Usage:
 *     import makeHttpRequest from '../helpers/ajax'
 *     var request = {url: ___, method: ___, storage_key: ___}
 *     makeHttpRequest(request).then(function(response) {
 *           ......
 *      }, function(error){
 *          .......
 *      });
 */

import appStorage from './app-storage';

const parseXml = function parseXml(xmlString) {
    return new window.DOMParser().parseFromString(xmlString, 'text/xml');
};

const makeHttpRequest = function makeHttpRequest(options) {
    if (!options.url) {
        return Promise.reject(new Error('URL not defined'));
    }

    // Use cache if storage_key is available
    const storageKey = options.storage_key;
    const expiration = options.expTime ? options.expTime : 60 * 60 * 1000;
    if (storageKey) {
        const storageData = appStorage.get(storageKey);
        if (storageData !== null) {
            return Promise.resolve(storageData);
        }
    }

    // Make an XMLHttpRequest and return a promise
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open(options.method || 'GET', options.url);
        xhr.onload = function onload() {
            let failureData;
            if (this.status >= 200 && this.status < 300) {
                const successData = xhr.responseText;
                if (successData && successData.toLocaleLowerCase() === 'notfound') {
                    resolve(successData);
                }
                try {
                    const jsonSuccessData = JSON.parse(successData);
                    let stringifyedSuccessData = JSON.stringify(successData);
                    if (storageKey && stringifyedSuccessData && stringifyedSuccessData.length > 0) {
                        stringifyedSuccessData = stringifyedSuccessData.toLocaleLowerCase();
                        if ((Object.hasOwn(jsonSuccessData, 'hasErrors') && jsonSuccessData.hasErrors) || jsonSuccessData.message === 'Something went wrong' || jsonSuccessData.term === 'Not Found') {
                            failureData = { status: this.status, statusText: xhr.statusText };
                            reject(JSON.stringify(failureData));
                        } else {
                            appStorage.set(storageKey, successData, expiration);
                        }
                    }
                } catch (error) {
                    const parsedData = parseXml(successData);
                    const xmlParsedData = parsedData.documentElement.outerHTML.toLocaleLowerCase();
                    if (xmlParsedData.indexOf('error') > 0 || xmlParsedData.indexOf('not found') > 0) {
                        failureData = { status: this.status, statusText: xhr.statusText };
                        reject(JSON.stringify(failureData));
                    } else {
                        appStorage.set(storageKey, successData, expiration);
                    }
                }
                resolve(successData);
            } else {
                failureData = { status: this.status, statusText: xhr.statusText };
                reject(JSON.stringify(failureData));
            }
        };

        xhr.onerror = function onerror() {
            const failureData = { status: this.status, statusText: xhr.statusText };
            reject(JSON.stringify(failureData));
        };

        if (options.headers) {
            Object.keys(options.headers).forEach((key) => {
                xhr.setRequestHeader(key, options.headers[key]);
            });
        }

        if (options.method === 'POST') {
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.send(JSON.stringify(options.params));
        } else {
            let params = options.params;
            if (params && typeof params === 'object') {
                params = Object.keys(params).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&');
            }
            xhr.send(params);
        }
    });
};

export default makeHttpRequest;

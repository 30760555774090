import trackData from './track-data';

export default {
    mounted() {
        if (window.ltanalytics) {
            this.firstInteractionTracking();
        }
    },
    props: {
        calcName: {
            default: 'no name specified',
        },
        calcParent: {
            default: 'no parent specified',
        },
    },
    data() {
        return {
            CalcName: this.calcName,
            CalculatorUsed: `${this.calcName}:${this.calcParent}`,
            CalculatorUsedFlag: 0,
            eventTriggered: false,
        };
    },
    methods: {
    // button click tracking for all input/dropdown fields
        redirect(value) {
            let target = value;
            const baseTarget = document.getElementById('baseTarget');
            if (window.ltanalytics) {
                trackData.set({ key: 'calculator_used', val: this.CalculatorUsed });
                window.ltanalytics.track('Widget Calculator Clicked', trackData.get());
            }
            if (baseTarget && !target) {
                target = baseTarget.value;
            }
            this.redirectToDestinationUrl(target);
        },
        setCalcTrackData() {
            let CalcDataItemId = '';
            const elmBody = document.getElementsByTagName('body');
            if (typeof elmBody == 'object' && elmBody.length > 0) {
                CalcDataItemId = elmBody[0].getAttribute('data-item-id');
            }
            if (CalcDataItemId && !this.CalculatorUsedFlag) {
                this.CalculatorUsed += `:${CalcDataItemId}`;
                this.CalculatorUsedFlag = 1;
            }
        },
        // Tracking on calculator input selection change
        firstInteractionTracking() {
            this.setCalcTrackData();
            let focusElements = document.querySelectorAll('#lt-yantr input, #lt-yantr select');
            if (focusElements.length === 0) {
                focusElements = document.querySelectorAll('#lt-yantr-v2 #inputs input, #lt-yantr-v2 #inputs select'); // new rate-table UI
            }
            if (!window.calledOnce && focusElements.length > 0) {
                for (let i = 0; i < focusElements.length; i++) {
                    focusElements[i].addEventListener('change', () => {
                        if (this.eventTriggered === false) {
                            window.ltanalytics.track('Widget Calculator Used', {
                                calculator_used: this.CalculatorUsed,
                            });
                            this.eventTriggered = true;
                        }
                    }, false);
                }
                window.calledOnce = true;
            }
        },
    },
};

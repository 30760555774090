const DebtManagementTooltip = {
    'dma-credit-card-debt': { term: 'Credit Card Debt', value: '<p>Enter your total credit card debt. If you have more than one card, add up all the balances and enter that number here.</p>' },
    'dma-personal-loans': { term: 'Personal Loans', value: '<p>Enter your total personal loan debt. If you have multiple personal loans, add the debt and enter the total amount here.</p>' },
    'dma-medical-expenses': { term: 'Medical Expenses', value: '<p>Enter any outstanding medical bills you owe.</p>' },
    'dma-other-debt': { term: 'Other Debt', value: '<p>Enter any other debt you might have, other than student or mortgage loans. For student loans, click on the \'High Student Loans\' link on the main page.</p>' },
    'dma-debt-settlement-option': { term: 'Debt Settlement', value: '<p>Debt relief providers will typically charge a fee for their services, expert advice, and actionable steps to improve your situation. These settings are based on the default averages most customers see in savings and fees. After talking with a debt settlement company, you can adjust these numbers for more accurate savings results.</p>' },
    'dma-credit-card-option': { term: 'Credit Card', value: '<p>We’re assuming a default minimum payment of 4 percent of your outstanding debt and APR. For more accurate savings results, enter your total current minimum payments and an average APR on your credit card debt (if you have more than one credit card). If your payments are less than 4 percent of your outstanding balance, the default will be considered for our calculations.</p>' },
    'dma-personal-loan-option': { term: 'Personal Loan', value: '<p>This is assuming the default APR and term based on your credit rating. For more accurate savings results, adjust the APR and term.</p>' },
    'dma-home-equity-option': { term: 'Home equity', value: '<p>This assumes you own a home and have enough equity to cover the home equity loan. We’re using the default APR and term based on your credit rating. For more accurate savings results, adjust the APR and term.</p>' },
    'dma-debt-settlement-savings': { term: 'Debt Settlement', value: '<p>A debt settlement company will negotiate your debt to help you get better monthly payments and interest rates. You’ll then pay your debt settlement company one monthly payment to go toward all your debt.</p><p>Debt settlement companies might not be able to settle medical debt, so debt-free predictions exclude medical debt.</p>' },
    'dma-balance-transfer-card-savings': { term: 'Balance Transfer Card', value: '<p>A balance transfer card lets you shift high-interest balances to a card that offers a lower APR. With low or zero interest, you can see immediate and substantial savings. Savings are based on the interest you would eliminate by transferring your balance(s) over the offer term.</p><p>The saving calculation assumes your total credit card balance(s) can be transferred, actual balance(s) on how much can be transferred could vary and is dependent on the chosen card. Interest calculated on your existing card is an estimation based on the minimum payments.</p><p>You might not be able to transfer a personal loan or medical debt to a balance transfer card, some cards might have a balance transfer fee associated (check the fine print of your chosen card).</p>' },
    'dma-home-equity-savings': { term: 'Home Equity Loan', value: '<p>Assuming that you have enough equity to cover the home equity loan. Savings are based on the interest you would eliminate by transferring your balance(s).</p><p>A home equity loan delivers a lump sum at closing and is repaid in monthly installments, this allows you to pay off other debt. A home equity loan allows the homeowner to borrow against their home equity (which is the difference between the property value and the mortgage balance(s) against it).</p>' },
    'dma-personal-loan-savings': { term: 'Personal Loan', value: '<p>Covers all types of debts, we are not accounting for any interest to be paid on medical and other debt. Savings are based on the interest you would eliminate by transferring your balance(s).</p> <p> A personal loan provides you with a lump sum of money, allowing you to pay off other debt. You replace multiple payments with a single monthly payment toward the personal loan. A personal loan has higher interest rates than secured loans like a home-equity loan, but you are not required to put up any collateral to ensure repayment.</p>' },
    'dma-debt-covered': { term: 'Debt Covered', value: '<p>This is the Debt amount being covered under the current option.</p>' },
    'dma-interest': { term: 'Interest', value: '<p>This is the interest you would pay over the offer term for this option.</p>' },
    'dma-monthly-payment': { term: 'Monthly Payment', value: '<p>This is your monthly payment for this option.</p>' },
    'dma-debt-not-covered': { term: 'Debt Not Covered', value: '<p>This is the Debt amount which is not covered under the current option</p>' },
};

const DebtManagementSavingDetail = {
    'personal-loan-detail': 'A personal loan provides you with a lump sum of money, allowing you to pay off other debt. You replace multiple payments with a single monthly payment toward the personal loan. Savings are based on the interest you would eliminate by transferring your balance(s). A personal loan has higher interest rates than secured loans like a home-equity loan, but you are not required to put up any collateral to ensure repayment.',
    'home-equity-detail': 'A home equity loan delivers a lump sum at closing and is repaid in monthly installments, this allows you to pay off other debt. Savings are based on the interest you would eliminate by transferring your balance(s). A home equity loan allows the homeowner to borrow against their home equity (which is the difference between the property value and the mortgage balance(s) against it).',
    'balance-transfer-card-detail': 'A balance transfer card lets you shift high-interest balances to a card that offers a lower APR. With low or zero interest, you can see immediate and substantial savings. Savings are based on the interest you would eliminate by transferring your balance(s) over the offer term. The saving calculation assumes your total credit card balance(s) can be transferred, actual balance(s) on how much can be transferred could vary and is dependent on the chosen card. Interest calculated on your existing card is an estimation based on the minimum payments. You might not be able to transfer a personal loan or medical debt to a balance transfer card, some cards might have a balance transfer fee associated (check the fine print of your chosen card).',
    'debt-settlement-detail': 'A debt settlement company will negotiate your debt to help you get better monthly payments and interest rates. You’ll then pay your debt settlement company one monthly payment to go toward all your debt. Debt settlement companies may not be able to settle medical debt.',
};

export default { DebtManagementTooltip, DebtManagementSavingDetail };

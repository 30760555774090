/**
 * This file reads attributes on div that start init- and convert them to props in beforeMount()
 */
import kebabCase from 'kebab-case';

export default {
    prop: ['dynamicPropsFromAttr'],
    render(createElement) {
        return createElement(
            'app',
            {
                props: this.dynamicPropsFromAttr,
            },
        );
    },
    beforeMount() {
        this.dynamicPropsFromAttr = {};
        for (let i = 0; i < this.$el.attributes.length; ++i) {
            const attr = this.$el.attributes[i];
            let attrName = attr.name;
            if (attrName.indexOf('init-') >= 0) {
                attrName = kebabCase.reverse(attrName.replace('init-', ''));
                let attrValue = '';
                try {
                    attrValue = JSON.parse(attr.value);
                } catch (e) {
                    attrValue = attr.value;
                }
                this.dynamicPropsFromAttr[attrName] = attrValue;
            }
        }
    },
};

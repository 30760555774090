/**
 * This provides an interface to push data into common object for tracking
 */
const trackData = {};

export default {
    set(obj) {
        const key = obj.key.replace(/[\W]/g, '').toLowerCase();
        trackData[key] = obj.val;
    },
    get() {
        return trackData;
    },
};

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c(
        "popper-view",
        {
          attrs: {
            "show-on-over": _vm.ShowOnOver,
            "hide-popper-tooltip": _vm.hidePopperTooltip,
          },
          on: {
            showPopper: function ($event) {
              return _vm.showPopper($event)
            },
            hidePopper: function ($event) {
              return _vm.hidePopper($event)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "popper",
              attrs: { id: _vm.trimLabel, role: "tooltip" },
            },
            [
              _c("div", {
                staticClass: "close lt4-ex",
                on: {
                  click: function ($event) {
                    return _vm.hidePopper()
                  },
                },
              }),
              _vm._v(" "),
              _c("div", { domProps: { innerHTML: _vm._s(_vm.popperContent) } }),
            ]
          ),
          _vm._v(" "),
          _vm.showQuestionMark
            ? _c("label", { attrs: { slot: "reference" }, slot: "reference" }, [
                _c("button", {
                  staticClass: "lt-icons lt4-QuestionOutline calc-question",
                  attrs: {
                    "aria-label": _vm.ariaLabel,
                    "aria-controls": _vm.trimLabel,
                    "aria-expanded": _vm.AriaExpanded,
                  },
                }),
              ])
            : _c(
                "button",
                { attrs: { slot: "reference" }, slot: "reference" },
                [_vm._v(" " + _vm._s(_vm.label) + " ")]
              ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }